<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :delete_url="delete_url"
      :urls="urls"
      @handleTokenModal="handleTokenModal"
    />

    <TokenModal ref="tokenModal" />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import AddItem from "@/own/components/customers/apps/AddItem";
import EditItem from "@/own/components/customers/apps/EditItem";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/apps.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import TokenModal from "@/own/components/Modals/TokenModal";
export default {
  name: "Apps",
  components: { TokenModal, DataTable },
  data: () => ({
    componentForEditing: EditItem,
    componentForAdding: AddItem,
    delete_url: `/authorizations/apps/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getAPPSTableFilters",
      getTableState: "getAPPSTableState",
      getTableData: "getAPPSTableData",
      getTableHeaders: "getAPPSTableHeaders",
      getTableProperties: "getAPPSTableProperties",
      getTableExportUrl: "getAPPSExportUrl",
      getTablePermissions: "getAPPSTablePermissions",
    },
    urls: {
      delete: `/authorizations/apps/destroy`,
      activate: `null`,
      status: `/authorizations/apps/status`,
      generate: "/authorizations/apps/generate",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
    handleTokenModal(token) {
      this.$refs.tokenModal.tokenDialog = true;
      this.$refs.tokenModal.tokenData = token;
      this.$store.commit(SET_PAGE_LOADING, false);
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
